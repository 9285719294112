.main {
  margin-top: 30px;
}

.operationBtn {
  display: inline-block;
  margin-right: 10px;
}

.formInput {
  display: inline-block;
  width: 100%;
}

.buttons {
  margin: 5px 0;
}
